body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;
}

*{
  font-family: 'SF Compact Text Bold' !important;

}

.foot_links .ant-collapse-header{
  color: white !important;
}

.connecticons a{
  color: inherit !important;
}
.ant-card{
  background: #ffffff00 !important;
  border: none !important;
  margin: 10px 0px;
  border-radius: 30px;
}

.ant-modal-content{
  backdrop-filter: blur(20px);
}

.ant-card-body, .ant-card-cover{
  padding: 10px !important;
}

.react-photo-album--photo:hover{
transform: scale(1.1);
transition: 0.2s;
}

#root{
  display: grid;
  overflow: clip;
  width: 100vw;
}

.dot{
  background: #4D93E7 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  background-color: #4D93E7 !important;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-drawer-content-wrapper{
  backdrop-filter: blur(25px);
}

.react-photo-album{
  width: 95vw !important
}

.control-arrow{
  background: rgb(40, 40, 40) !important;
}

.foot_links a{
  font-weight: 100;
  padding: 7px 0px;
  letter-spacing: 3px;
  font-family: 'SF Compact Text Regular' !important;
}

.credit{
  font-weight: 900;
  color: yellow
}

.credit:hover{
  color: orange
}

@font-face {
  font-family: 'SF Compact Text Bold';
  src: url('../public/SFPRODISPLAYBOLD.OTF');
}

@font-face {
  font-family: 'SF Compact Text Regular';
  src: url('../public/SFPRODISPLAYREGULAR.OTF');
}

.ant-modal-header{
  background: transparent !important;
}

.filler{
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
  font-weight: bolder !important;
}

a{
  text-decoration: none;

}

.carousel-root{
  width: 95vw;
  justify-self: center;
  margin-top: 10px;
  cursor: pointer;

}

.carousel:nth-child(2){
  display: none;
}
.carousel{
  border-radius: 35px;
}

.drawer_links_holder > div{
  background:  rgb(128, 128, 128, 0.1);
  padding: 5px;
  margin: 2px 0px;
  border-radius: 10px;
}

.childholder a{
  width: 100%;
  text-align: center;
  border-left: 1px solid grey;
  padding: 3px;
}

.rfm-child{
  padding: 0px 10px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
